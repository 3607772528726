import React from 'react';

/**
 * Paste in your SVG logo and return it from this component.
 * Make sure you have a height set for your logo.
 * It is recommended to keep the height within 25-35px.
 */
export default function Logo() {
  return (
    // <svg width="240" height="35">
    //   <circle fill="#FF7E5F" cx="15" cy="15" r="15"/>
    //   <text x="35" y="20"  fill="grey">That</text>
    //   <text x="70" y="20" fill="#FF7E5F" font-weight="bold">LEVERAGE</text>
    // </svg>
    <svg width="75" height="75" xmlns="http://www.w3.org/2000/svg">
 <g>
  <title>background</title>
  <rect fill="#fff" id="canvas_background" height="75" width="75" y="0" x="0"/>
 </g>
 <g>
  <title>Layer 1</title>
  <path id="svg_2" d="m1,280.375l149,-260.75l149,260.75l-298,0z" fill-opacity="null" stroke-opacity="null" stroke-width="0" stroke="#000" fill="#ff3864"/>
  <rect id="svg_3" height="75" width="75" y="0" x="0" fill-opacity="null" stroke-opacity="null" stroke-width="0" stroke="#000" fill="#ff3864"/>
  <rect id="svg_4" height="0" width="0.155038" y="25.787356" x="-20.561765" fill-opacity="null" stroke-opacity="null" stroke-width="0" stroke="#000" fill="#ff3864"/>
  <path stroke="#000" transform="rotate(180 34.77735137939453,37.51921463012695) " id="svg_5" d="m-5.299996,75.03843l40.077348,-75.038431l40.077348,75.038431l-80.154695,0z" stroke-opacity="null" stroke-width="0" fill="#ffffff"/>
 </g>
</svg>

  );
}
